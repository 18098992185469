import {parse} from 'json2csv';
import {aMaxVal, va} from './arrayUtils';
import {cfl, flc, pez} from './stringUtils';
import {ChartData, NS, T, User} from '../model';
import {Path} from '../routes';
import {__NODE_ENV__, __REACT_APP_ENV__, GoogleDocsViewerURL, ipApiUrl, ROLES} from '../constants';

// ################## Project Arch Specific ################################

/**
 * Page Title
 * @param path
 */
export const setPageTitle = (path: string) => {
  const searchVal = new RegExp(/[^a-zA-Z ]/g);
  const separator = ' ';
  const title = path.replace(searchVal, separator).split(separator).map((w: string) => cfl(w)).join(separator);
  if (document) {
    document.title = title
  }
};

/**
 * Staging server CSS
 */
export const setStageCss = () => {
  const qualifiedName = 'class';
  if (__REACT_APP_ENV__ && __REACT_APP_ENV__ === 'staging' && document) {
    const cls = `${document.body.getAttribute(qualifiedName)} stage`;
    document.body.setAttribute(qualifiedName, cls);
  } else if (__NODE_ENV__ === 'development' && document) {
    const cls = `${document.body.getAttribute(qualifiedName)} dev`;
    document.body.setAttribute(qualifiedName, cls);
  }
};

/** Pagination helper
 * @param activePage
 * @param gridSize
 */
export const getSkipLimit = (activePage: number, gridSize: number) => (activePage - 1) * gridSize;

/**
 * No operation, Empty function
 */
export const noop = () => {
};

/**
 * Is Valid Function
 * @param f
 */
export const isFunction = (f: T) => {
  return typeof f === 'function';
};

type BO = boolean;
type ASN = Array<string | number>;
/**
 * Parse string array as dropdown options
 * @param strArr
 * @param hasAnEmptyOpt
 */
export const mapSelectOption = (strArr: ASN, hasAnEmptyOpt: BO = false) => {
  const selOpts = strArr.map((opt, key) => ({key, value: opt, text: opt}));
  if (hasAnEmptyOpt && selOpts.findIndex(i => i.key === -1) === -1) {
    selOpts.unshift({key: -1, value: '', text: ''});
  }
  return selOpts;
};

type O = { [key: string]: string | number }
export const mapSelectOpts = (obj: O, hasAnEmptyOpt: BO = false) => {
  const selOpts = Object.keys(obj).map((opt, key) => ({key, text: opt, value: obj[opt]}));
  if (hasAnEmptyOpt && selOpts.findIndex(i => i.key === -1) === -1) {
    selOpts.unshift({key: -1, value: '', text: ''});
  }
  return selOpts;
};

/**
 * parse menu item name as its link url
 * @param menu - name
 */
export const parseMenuAsUrl = (menu: string) => `/${flc(menu).split(' ').join('-')}`;

/**
 * Parse Location Path as Menu Name
 * @param pathname
 */
export const parseLocPathAsMenu = (pathname: string) => {
  const splitter = '/';
  const splitterPath = pathname.split(splitter);
  let filteredPath = splitterPath[splitterPath.length - 1];
  filteredPath = filteredPath.length > 1 ? filteredPath : splitterPath[splitterPath.length - 2];

  return filteredPath.split('-').map(pn => cfl(pn)).join(' ');
}

/**
 * join first name & last name as full name
 * @param fn
 * @param ln
 */
export const joinAsFullName = (fn?: string | undefined, ln?: string | undefined) =>
  (fn && ln) ? `${cfl(pez(fn))} ${cfl(pez(ln))}` : cfl(pez(fn || ln || ''));

/**
 * Get Questionnaire Route
 * @param role
 * @param projectId
 */
export const getQuestionnaireViewerRoute = (role: number, projectId: number) => {
  let qsViewerUrl = '/';
  switch (role) {
    case ROLES['Project Manager']:
      qsViewerUrl = Path.PMQuestionnaireViewer;
      break;
    case ROLES.Operations:
      qsViewerUrl = Path.OPQuestionnaireViewer;
      break;
    case ROLES.Surveyor:
      qsViewerUrl = Path.SurveyorQuestionnaireViewer;
      break;
    case ROLES['CIMR Surveyor']:
      qsViewerUrl = Path.CCSQsViewer;
      break;
    case ROLES['CIMR Manager']:
      qsViewerUrl = Path.CIMRViewer;
      break;
    case ROLES['Keller Surveyor']:
      qsViewerUrl = Path.KellerSurveyorQsViewer;
      break;
    default:
      break;
  }
  return `${qsViewerUrl}/${projectId}`;
};

/**
 * filter role based auth url
 * @param auth
 */
export const filterAuthRoute = (auth: User) => {
  let authUrl = '/';
  const isAuthentic = !!(auth && auth.user && auth.user.id && auth.accessToken);
  const authRole = isAuthentic ? (auth && auth.user && auth.user.role) : 10;

  switch (authRole) {
    case ROLES.Admin:
      authUrl = Path.AdminAccounts;
      break;
    case ROLES['Project Manager']:
      authUrl = Path.Projects;
      break;
    case ROLES.Operations:
      authUrl = Path.OPSProjects;
      break;
    case ROLES.Surveyor:
      authUrl = Path.SurveyorProjects;
      break;
    case ROLES.Quality:
      authUrl = Path.QACallLog;
      break;
    case ROLES['Comment Cleaner']:
      authUrl = Path.CCProjects;
      break;
    case ROLES['CIMR Surveyor']:
      authUrl = Path.CCSProjects;
      break;
    case ROLES['CIMR Manager']:
      authUrl = Path.CIMRAllSurveyors;
      break;
    case ROLES['Keller Surveyor']:
      authUrl = Path.KellerProjects;
      break;
    default:
      break;
  }

  return {authUrl, isAuthentic, authRole};
};

/**
 * Convert JSON to CSV
 * @param jsonItems
 * @param withBOM
 */
export const jsonToCsv = (jsonItems: T[], withBOM?: boolean | undefined) => {
  const opts = {withBOM};
  return parse(jsonItems, opts);
}

/**
 * Download File As CSV
 * @param fileName
 * @param fileData
 */
export const downloadAsCSV = (fileName: string, fileData: string) => {
  const fileNameWithExt = fileName ? `${fileName}.csv` : 'export.csv';
  const blob = new Blob([fileData], {type: 'text/csv;charset=utf-8;'});
  if (navigator.msSaveBlob) { // IE 10+
    navigator.msSaveBlob(blob, fileNameWithExt);
  } else {
    const link = document.createElement('a');
    if ((link.download !== undefined)) { // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileNameWithExt);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

/**
 * Get Survey Max Value
 * @param histArr
 */
export const surveyMaxValue = (histArr: ChartData[]) => {
  const defaultMaxVal = 10;

  if (!va(histArr)) {
    return defaultMaxVal;
  }

  const valMax = aMaxVal(histArr.map(a => a.cSurveys).concat(histArr.map(a => a.lineValue)));
  const valMax10percent = Math.ceil(valMax * 0.1);

  return valMax < defaultMaxVal ? defaultMaxVal : (valMax + valMax10percent);
};

/**
 * Opt Secret Key
 * @param projectId
 * @param callId
 * @param reason
 */
export const optOutSecretKey = (projectId: string, callId: number, reason: string = 'optOut') => {
  return `${reason}-${projectId}-${callId}`;
};

export const checkIfSurCallPath = (locPath: string) => {
  const start = 0;
  const end = locPath.lastIndexOf('/');
  const locPathName = locPath.substr(start, end);
  return locPathName === Path.CCSProjectSurvey || locPathName === Path.SurveyorProjectSurvey;
}

export const checkIfProfilePath = (locPath: string) => {
  return locPath === Path.MyProfile;
}

/**
 * Weekly vs Required Survey Goals
 * @param compGoals
 * @param reqGoals
 */
export const surveyGoals = (compGoals: NS | undefined, reqGoals: number | string | undefined) => {
  return `${compGoals} / ${reqGoals}`;
};

/**
 * Google Docs viewer for Iframe
 * @param encodedUrl
 */
export const fileUrl = (encodedUrl: string) => `${GoogleDocsViewerURL}?url=${encodedUrl}&embedded=true`;

/**
 * Get textarea or div text content
 * @param elemId
 */
export const getTextContent = (elemId: string) => {
  const editableElem = document.getElementById(elemId);
  return pez(editableElem?.textContent as string);
};

/**
 * Get My Ip
 */
export const getMyIp = async () => {
  let resp: string | null = '';
  await fetch(ipApiUrl).then((response) => {
    return response.json();
  }).then((res) => {
    resp = res ? res.ip : null;
  }).catch((err) => {
    if (err) {
      resp = null;
    }
  });

  return resp;
};

export const pdcChartHeight = () => {
  const elemId = 'pdc';
  const clientHeight = window.document.getElementById(elemId)?.parentElement?.clientHeight || 0;
  return clientHeight - (20 / 100 * clientHeight);
};

export const isValidSearch = (value: string, loader: boolean, minLength: number = 0) => {
  return !(value.length >= minLength) || loader;
};
