import {Action, ActionType, Call, CallLog, CallLogList, CallsHistory, CallStat, SurveyorEarning, LookUp} from '../../model';
import createReducer from './createReducer';

const initCalls: Partial<Call> = {};
const initCallsHist: Partial<CallsHistory[]> = [];
const initCallLogs: Partial<CallLogList> = {};
const initCallLog: Partial<CallLog> = {};
const initLookUp: Partial<LookUp> = {};

export const visitCall = createReducer(initCalls, {
  [ActionType.SURVEYOR_FETCH_CALL_INFO](_store: Function, action: Action<Call>) {
    return action.payload;
  },

  [ActionType.FLUSH_CALLS_CHARTS](_store: Function, action: Action<void>) {
    return action.payload;
  }
});

export const callsHistory = createReducer(initCallsHist, {
  [ActionType.FETCH_CALLS_CHARTS](_store: Function, action: Action<CallsHistory[]>) {
    return action.payload;
  },

  [ActionType.FLUSH_CALLS_CHARTS](_store: Function, action: Action<void>) {
    return action.payload;
  }
});

export const callLogs = createReducer(initCallLogs, {
  [ActionType.FETCH_CALL_LOGS](_store: Function, action: Action<CallLogList>) {
    return action.payload;
  },

  [ActionType.FLUSH_CALL_LOGS](_store: Function, action: Action<void>) {
    return action.payload;
  }
});

export const callLog = createReducer(initCallLog, {
  [ActionType.FETCH_CALL_LOG_INFO](_store: Function, action: Action<CallLog>) {
    return action.payload;
  },

  [ActionType.FLUSH_CALL_LOG_INFO](_store: Function, action: Action<void>) {
    return action.payload;
  }
});

export const chartEarnings = createReducer([], {
  [ActionType.CHART_EARNINGS](_store: Function, action: Action<SurveyorEarning>) {
    return action.payload;
  },

  [ActionType.FLUSH_CHARTS_EARNINGS](_store: Function, action: Action<void>) {
    return action.payload;
  },
});

export const callStats = createReducer([], {
  [ActionType.CALL_STATS](_store: Function, action: Action<CallStat>) {
    return action.payload;
  },

  [ActionType.FLUSH_CALL_STATS](_store: Function, action: Action<void>) {
    return action.payload;
  },
});

export const chartStats = createReducer([], {
  [ActionType.CHART_CALL_STATS](_store: Function, action: Action<CallStat>) {
    return action.payload;
  },

  [ActionType.FLUSH_CHART_CALL_STATS](_store: Function, action: Action<void>) {
    return action.payload;
  },
});

export const lookUp = createReducer(initLookUp, {
  [ActionType.FETCH_LOOK_UP](_store: Function, action: Action<CallLogList>) {
    return action.payload;
  },

  [ActionType.FLUSH_LOOK_UP](_store: Function, action: Action<void>) {
    return action.payload;
  }
});
