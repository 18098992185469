// @formatter:off

export type T = any;

export * from './assignment'

export * from './misc'

export * from './client';

export * from './project';

export * from './user';

export * from './inputs'

export * from './components'

export * from './call';

export * from './comment';

export * from './roster';

export * from './sampling';

export * from './language';

export * from './qa';

export * from './survey';

export * from './escalation';

export * from './sr';

export enum ActionType {
  ALERT = 1,
  MESSAGE,

  NAV_EXPAND_COLLAPSE,

  LOAD_SESSION,
  LOGIN_BEGIN,
  LOGIN_SUCCESS,
  LOGOUT,

  FORGOT_PASSWORD,
  TOKEN_VERIFY,
  RESET_PASSWORD,
  FLUSH_ACC_STORE,

  ACC_CSV_EXPORT,

  ADMIN_PULL_USERS_BEGIN,
  ADMIN_PULL_USERS_SUCCESS,
  ADMIN_ADD_USER,
  ADMIN_USER_INFO,
  ADMIN_DEL_USER,
  ADMIN_PUT_USER,
  ADMIN_RE_INVITE,
  ADMIN_PULL_USERS_FLUSH,
  USER_INFO_FLUSH,
  ADMIN_USER_DEACTIVATE,

  ADMIN_PULL_CLIENTS,
  ADMIN_CLIENTS_FLUSH,
  ADMIN_CLIENTS_ADD,
  ADMIN_CLIENT_INFO_FLUSH,
  ADMIN_CLIENT_INFO,
  ADMIN_DEL_CLIENT,
  ADMIN_DEL_CLIENT_CONTACT,
  ADMIN_PUT_CLIENT,
  ADMIN_FETCH_EXT_USER,
  ADMIN_FLUSH_EXT_USER,

  ASSIGNMENT_HISTORY_FETCH,
  ASSIGNMENT_HISTORY_BULK_ADD,
  ASSIGNMENT_HISTORY_ADD,
  ASSIGNMENT_HISTORY_DELETE,
  ASSIGNMENT_HISTORY_FLUSH,
  ASSIGNMENT_HISTORY_UPDATE,
  ASSIGNMENT_HISTORY_WEEKLY_DATA,
  ASSIGNMENT_HISTORY_WEEKLY_DATA_FLUSH,

  ESCALATION_FETCH,
  ESCALATION_FETCH_BY_ID,
  UPDATE_ESCALATION_NOTES,
  ESCALATION_FLUSH,
  ESCALATION_FLUSH_BY_ID,

  COMMENTS_FETCH,
  COMMENTS_CLEAN,
  COMMENTS_UNCLEAN,
  COMMENTS_FLUSH,

  // FETCH_ALL_SURVEYOR,
  FLUSH_SURVEYOR,
  FLUSH_SURVEYOR_INFO,

  PULL_PROJECTS,
  PROJECTS_FLUSH,
  PROJECT_WEEKLY_DETAILS,
  PROJECT_WEEKLY_DETAILS_FLUSH,
  PM_SURVEYORS_FETCH,
  PM_SURVEYORS_INFO_FETCH,
  PM_SURVEYORS_FLUSH,
  PM_SAMPLING_FETCH,
  PM_SAMPLING_ADD,
  PM_SAMPLING_UPDATE,
  PM_SAMPLING_DEL,
  PM_SAMPLING_FLUSH,
  PM_SAMPLING_ADDITIONAL_OPN,
  PM_DEPARTMENTS,
  PM_PROVIDERS,
  PM_SITES,
  PM_DEPARTMENTS_FLUSH,
  PM_PROVIDERS_FLUSH,
  PM_SITES_FLUSH,
  PM_SAMPLING_LANGUAGES,
  PM_VISIT_INFO,
  PM_VISIT_INFO_FLUSH,
  PM_UPDATE_VISIT_INFO,
  PM_UPDATE_STATUS,
  PM_UPDATE_DATE,

  SR_PROJECTS_FETCH,
  SR_SAVE,
  SR_DOWNLOAD,
  SR_FETCH,
  SR_HEADER_KEYS,
  SR_PROJECTS_FLUSH,
  SR_FLUSH,
  SR_HEADER_FLUSH,

  ADMIN_PROJECTS_ADD,
  ADMIN_PROJECT_INFO,
  ADMIN_PUT_PROJECTS,
  ADMIN_PROJECT_INFO_FLUSH,
  ADMIN_DEL_PROJECT,

  UPLOAD_ROSTERS,
  FLUSH_ROSTERS,
  FETCH_ROSTERS,
  UNDO_ROSTERS,

  OPS_ASSIGNMENTS_LIST,
  OPS_ASSIGNMENTS_FLUSH,
  OPS_ASSIGN_SURVEYORS,

  SURVEYOR_CALL_RESPONSE,
  SURVEYOR_FETCH_CALL_INFO,
  SURVEYOR_CALL_LOCK,
  SURVEYOR_CALL_UN_LOCK,
  SURVEYOR_EXPORT_CSV_SUCCESS,
  SURVEYOR_EARNINGS,
  CHART_EARNINGS,
  CALL_STATS,
  CHART_CALL_STATS,
  FLUSH_CHART_CALL_STATS,
  FLUSH_CALL_STATS,
  FLUSH_SURVEYOR_EARNINGS,
  FLUSH_CHARTS_EARNINGS,
  FLUSH_CALLS_CHARTS,
  FETCH_CALLS_CHARTS,

  FETCH_CALL_LOG_INFO,
  FETCH_CALL_LOGS,
  FLUSH_CALL_LOG_INFO,
  FLUSH_CALL_LOGS,
  PUT_CALL_LOG_INFO,

  SAVE_CALL_SURVEY,

  FETCH_LANGUAGES,
  FLUSH_LANGUAGES,
  ADD_LANGUAGES,
  DEL_LANGUAGES,

  QUESTIONS_FETCH,
  QUESTIONS_ADD,
  QUESTIONS_PUT,
  QUESTIONS_DEL,
  QUESTIONS_LANG_DEL,
  QUESTIONS_FLUSH,
  QUESTION_PROJECT_LIST,
  QUESTION_PROJECT_FLUSH,

  ANSWERS_FETCH,
  ANSWERS_ADD,
  ANSWERS_PUT,
  ANSWERS_DEL,
  ANSWERS_LANG_DEL,
  ANSWERS_FLUSH,

  QUESTIONNAIRE_LIST_FETCH,
  QUESTIONNAIRE_FETCH,
  QUESTIONNAIRE_LOGIC_FETCH,
  QUESTIONNAIRE_ADD,
  QUESTIONNAIRE_LOGIC_SAVE,
  QUESTIONNAIRE_LOGIC_UPDATE,
  QUESTIONNAIRE_COPY,
  QUESTIONNAIRE_CHANGE_SEQUENCE,
  QUESTIONNAIRE_CSV_EXPORT,
  QUESTIONNAIRE_DEL,
  QUESTIONNAIRE_LOGIC_DEL,
  QUESTIONNAIRE_LIST_FLUSH,
  QUESTIONNAIRE_LOGIC_FLUSH,
  QUESTIONNAIRE_FLUSH,
  QUESTIONNAIRE_UPDATE,

  SAVE_SV_GUIDELINE,
  FETCH_SV_GUIDELINE,
  FETCH_SV_GUIDELINE_STATUS,
  FLUSH_SV_GUIDELINE,
  FETCH_LOOK_UP,
  FLUSH_LOOK_UP
}
