import React from 'react';
import {useDispatch} from "react-redux";
import {connect, useSelector} from 'react-redux'
import { IconGroup } from 'semantic-ui-react'
import {
  Account,
  CallLog,
  CallLogList,
  CLUPayload,
  FormikValues,
  IconProps, LookUp,
  MiniAccount,
  ProjectsList,
  ST,
  StoreT,
  T
} from '../../model';
import {Card, FormWrap, GenModelProps, Grid, GridView, Icon, Model, Paging} from '../Inputs';
import {Loader} from '../index';
import {CLR, Filters} from './index';
import {DEFAULT_RECORDS_PER_PAGE, initFalsy, ROLES, SurCallResponse, SurveyStatus} from '../../constants';
import {useActions} from '../../redux/actions'
import * as callActions from '../../redux/actions/calls'
import * as projectActions from '../../redux/actions/projects';
import * as userAccActions from '../../redux/actions/users';
import {va} from '../../utils/arrayUtils';
import {getSkipLimit} from '../../utils/common';
import {vo} from '../../utils/objectUtils';
import { Path } from '../../routes';
import { setAlert } from "../../redux/actions/alert";
import {useHistory} from "react-router";

interface CLDProps {
  callLogs: CallLogList;
  lookUp: LookUp;
  isManager?: boolean;
  isOPS?: boolean;
  projects: ProjectsList;
  surveyors: Array<MiniAccount>;
  user: Account;
}

type FU = (
  skip?: number,
  lim?: number,
  sortBy?: ST,
  search?: string,
  projectId?: number
) => Promise<T>;

type Filter = {
  projectId?: number;
  search?: string;
};

const CallLogGrid = (props: CLDProps) => {
  const hist = useHistory();
  const initPageNo = 1;
  const { user, isOPS, lookUp } = props;
  const surveyCompText = 'Survey Complete';
  const callLogGridHeaders = [
    'Cell Phone',
    'Home Phone',
    'Call Status',
    'Survey Status',
    'Name',
    'Project',
    'View Survey'
  ];

  const initSort: { column: string; direction: ST } = {column: 'surveyedOn', direction: 'DESC'};
  const initFilter: Filter = {search: ''};
  const cat = useActions(callActions);
  const pro = useActions(projectActions);
  const userAction = useActions(userAccActions);
  const callInfo: CallLog = useSelector((state: StoreT) => state.callLog);
  const [loading, setLoading] = React.useState(initFalsy);
  const [editing, setEditing] = React.useState(initFalsy);
  const [limit, setPageSize] = React.useState(DEFAULT_RECORDS_PER_PAGE);
  const [activePage, setPageChange] = React.useState(initPageNo);
  const [sortVars, setSortVars] = React.useState(initSort);
  const [filter, setFilter] = React.useState(initFilter);
  const dispatch = useDispatch();
  const onAction = async (id: number) => {
    setLoading(!initFalsy);
    setEditing(!initFalsy);
    await cat.fetchCallLogInfo(id);
    setLoading(initFalsy);
  };
  const onCall = (pid: number, visitorID: number, visitID: number) => {
    let path:string = ""
    if(user.role === ROLES.Surveyor) {
      path = `${Path.NcqaSurveyorProjectSurvey}/${pid}`;
    } else if(user.role === ROLES['CIMR Surveyor']) {
      path = `${Path.CCSProjectSurvey}/${pid}`;
    } else if(user.role === ROLES['Keller Surveyor']) {
      path = `${Path.KellerSurveyorProjectSurvey}/${pid}`;
    } else {
      dispatch(setAlert({ message:"Not allowed to call", statusCode:400 }));
    }
    hist.push({ pathname: path, state: {visitorID, visitID} });
  };

  const onResponseCancel = async () => {
    await cat.flushCallLogInfo();
    setEditing(initFalsy);
  };
  const onReset = async () => {
    await onResponseCancel();
    setFilter(initFilter);
    setPageChange(initPageNo);
  };
  const onSubmit = async (values: FormikValues, {resetForm}: FormikValues) => {
    try {
      setSortVars(initSort);
      const {
        projectId,
        search
      } = values;

      setFilter(values as Filter);

      await fetchLookUp(0, limit, undefined, search, projectId);
    } catch (err) {
      resetForm();
    }
  };
  const fetchLookUp: FU = async (skip = 0, limit = DEFAULT_RECORDS_PER_PAGE, sortBy = initSort.direction, search, projectId) => {
    setLoading(!initFalsy);
    await cat.fetchLookUp(skip, limit, sortBy, search, projectId);
    setLoading(initFalsy);
  }
  const navFux = async (activePage: number, gridSize: number) => {
    const {projectId, search} = filter;
    const skip = getSkipLimit(activePage, gridSize);

    await fetchLookUp(skip, gridSize, sortVars.direction, search, projectId);
  };
  const onUpdate = async (updCallLogPayload: CLUPayload) => {
    await cat.putSurCallLog(updCallLogPayload);
    const {projectId, search} = filter;
    const skip = getSkipLimit(activePage, limit);

    await fetchLookUp(skip, limit, sortVars.direction, search, projectId);
    setEditing(initFalsy);
  };

  const lyfFstEvent = () => {
    const [projectSortOn, projectSortBy] = ['name', 'ASC'];
    const fetchProjects = props.isManager ?
      pro.fetchProjects(0, Number.MAX_SAFE_INTEGER, projectSortOn, projectSortBy, undefined, isOPS ? undefined : user.id, !initFalsy) :
      pro.fetchSurveyorProjects(0, Number.MAX_SAFE_INTEGER, projectSortOn, projectSortBy, user.id);

    fetchLookUp()
      .then(fetchProjects)

    return () => {
      pro.flushProjects();
      cat.flushLookUp();
      userAction.flushSurveyUserList();
    };
  };
  const actionProps: IconProps = {name: 'eye', title: 'View Details'};

  const logs = va(lookUp.rows) ? lookUp.rows.map((log: any) => {
    const {
      id,
      call,
      visitor
    } = log;

    const { phoneCell, phoneHome, lastResult, name, project } = visitor;

    let pID:null|number = (visitor && visitor.project && visitor.project.id) || null;
    let visitorID:null|number = (visitor && visitor.id) || null;
    let visitID:null|number = id || null;

    const dnd = SurCallResponse['Do Not Call'];
    const dndClass = dnd === lastResult ? 'red' : '';
    const resultText = lastResult === SurCallResponse['Agreed to Survey'] ? surveyCompText : SurCallResponse[lastResult];
    const callIconProps: IconProps = {className: 'hand-pointer', name: 'call', title: 'Call'};
    const surveyStatus = (call === null) ? "" : (call && call.isPartial) ? SurveyStatus[SurveyStatus.Partial] : SurveyStatus[SurveyStatus.Completed]
    return {
      phoneCell,
      phoneHome,
      callResult: (<div className={dndClass}>{resultText}</div>),
      surveyStatus,
      name,
      project: project?.name,
      action: (

        <>
          {!!(call && call.callHistoryId) ? < Icon className="hand-pointer" onClick={() => onAction((call && call.callHistoryId) as number)} {...actionProps} />:
            <>
              {
                (user.role === ROLES.Surveyor || user.role === ROLES['CIMR Surveyor'] || user.role === ROLES['Keller Surveyor']) ? <Icon className="hand-pointer" onClick={() => onCall(pID as number, visitorID as number, visitID as number)} {...callIconProps}/>:
                  <IconGroup size='small' >
                    <Icon size='large' color='red' name='dont' />
                    <Icon className="hand-pointer" name='call' style={{margin:0}}/>
                  </IconGroup>
              }
            </>
          }
        </>
      )
    }
  }) : [];
  const CLRProps = {
    callInfo,
    onCancel: onResponseCancel,
    onUpdate,
    users: user
  };
  const modelContent = (
    <>
      {
        vo(callInfo) ? <CLR {...CLRProps} /> : <div style={{height: '100px'}}><Loader /></div>
      }
    </>
  );
  const filtersFormConfig = {
    children: Filters,
    displayName: 'call-log-filter',
    // exporting,
    initialValues: {
      projectId: '',
      search: ''
    },
    isManager: props.isManager,
    onReset,
    onSubmit,
    projects: props.projects,
    surveyors: props.surveyors,
  };
  const gridProps = {
    headers: callLogGridHeaders,
    list: logs
  };
  const modelProps: GenModelProps = {
    centered: !initFalsy,
    closeOnDimmerClick: initFalsy,
    closeOnEscape: initFalsy,
    content: modelContent,
    hasClose: initFalsy,
    initialize: editing,
    onCancel: () => {
      setEditing(initFalsy)
    },
    scrolling: !initFalsy,
    size: 'fullscreen'
  };
  const pagingProps = {
    totalRecords: lookUp.count,
    navigate: navFux,
    activePage: activePage,
    pageSize: limit,
    setPageSize,
    setPageChange
  };

  React.useEffect(lyfFstEvent, []);

  return (
    <Grid>
      {
        loading &&
        <Loader />
      }

      <Grid.Row className="headerTop">
        <Grid.Column width={16}>
          <h1 className="mainTitle">Look Up</h1>
        </Grid.Column>
      </Grid.Row>

      {
        <FormWrap {...filtersFormConfig} />
      }

      {
        editing &&
        <Model {...modelProps} />
      }

      <Grid.Row>
        <Grid.Column width={16}>
          <Card fluid={!initFalsy}>
            <Card.Content>
              <GridView {...gridProps} />
              {lookUp.count > 0 && <Paging {...pagingProps} />}
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
};

const mp = (store: StoreT) => ({
  callLogs: store.callLogs,
  lookUp: store.lookUp,
  projects: store.projects,
  surveyors: store.surveyorInfo,
  user: store.auth.user
});

export default connect(mp)(CallLogGrid);
