import React from 'react';
import {Button, Card, Form, FormFieldGroup, FormFieldType, Grid, SemanticWIDTHS} from '../Inputs';
import {ButtonProps, FormFieldProps, FormProps, MiniAccount, ProjectsList, SelectValue} from '../../model';
import {initFalsy, SurCallResponse} from '../../constants';
import {enumAsAO, va} from '../../utils/arrayUtils';
import isMobileDevice from '../../utils/deviceUtils';

interface Props extends FormProps {
  exporting: boolean;
  isManager: boolean;
  onReset: Function;
  projects: ProjectsList;
  surveyors: Array<MiniAccount>;
  onCallCSVExport: Function;
}


type cols = { computer: SemanticWIDTHS, tablet: SemanticWIDTHS }
type FFPs = (FormFieldProps & { cols: cols });

const Filters: React.FC<Props> = (props) => {
  const {
    handleSubmit,
    isSubmitting,
    projects,
    resetForm,
    values,
  } = props;

  const [selProjectId, setProjectId] = React.useState();
  const onReset = () => {
    resetForm();
    props.onReset();
  };
  const csOpts = enumAsAO(SurCallResponse);
  const proOpts = projects?.rows.map(p => ({'text': p.name, 'value': p.id}));
  const commonSPs = {className: 'float-right'};
  const agreeSurveyIdx = csOpts.findIndex(i => i.value === SurCallResponse['Agreed to Survey']);

  if (va(csOpts) && agreeSurveyIdx > -1) {
    const surveyCompText = 'Survey Complete';
    csOpts.splice(agreeSurveyIdx, 1, {...csOpts[agreeSurveyIdx], text: surveyCompText});
  }

  const filterFields: FFPs[] = [
    {
      className: '',
      cols: {computer: 4, tablet: 8},
      defaultValue: selProjectId,
      fluid: initFalsy,
      label: 'Project',
      name: 'projectId',
      onChange: (value: SelectValue) => setProjectId(value),
      options: proOpts,
      placeholder: 'Select Project',
      type: FormFieldType.Select
    },
    {
      ...commonSPs,
      className: 'float-left',
      cols: {computer: 4, tablet: 8},
      label: 'Search via Phone Number / name...',
      name: 'search',
      type: FormFieldType.Text,
      value: values.search
    }
  ];

  const buttons = [
    {content: 'Filter', className: 'mt10', disabled: isSubmitting, loading: isSubmitting, primary: !initFalsy},
    {content: 'Reset', className: `mt10 ${isMobileDevice ? '' : 'ml10'}`, onClick: onReset, secondary: !initFalsy}
  ];

  return (
    <Grid.Row>
      <Grid.Column width={16}>
        <Card>
          <Card.Content>
            <Form className="filters" onSubmit={handleSubmit}>
              <Grid>
                <Grid.Row>
                  {
                    filterFields.map(({cols, ...f}, k) => (
                      <Grid.Column key={k} mobile={16} {...cols}>
                        <FormFieldGroup fields={f} />
                      </Grid.Column>
                    ))
                  }
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column computer={16} mobile={8}>
                    {
                      buttons.map((btProps: ButtonProps, idx) => (
                        <Button key={idx} {...btProps} />
                      ))
                    }
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Card.Content>
        </Card>
      </Grid.Column>
    </Grid.Row>
  );
};

export default Filters;
