import {NavItem} from '../model';
import {isDev} from './constants';

type Nav = { [key: string]: NavItem[] };

export const NAV: Nav = {
  Admin: [
    {name: 'Account Management', icon: 'fa-user-cog'},
    {name: 'Client Management', icon: 'fa-users'},
    {name: 'Project List', icon: 'fa-list-ul'},
    {name: 'Languages', icon: 'fa fa-language'},
    {name: 'External Login Attempts', icon: 'fa-user-secret'}
  ],
  CC: [
    {name: 'Comment Cleaning Selection', icon: 'fa-comments'},
    {name: 'User Directory', icon: 'fa-user-cog'}
  ],
  CCSurveyor: [
    {name: 'My Projects', icon: 'fa-clipboard-list'},
    {name: 'Call Log', icon: 'fa-history'},
    {name: 'Surveyor Call Stats', icon: 'fa-chart-bar'},
    {name: 'Look Up', icon: 'fa-search-plus'}
  ],
  CIMR: [
    {name: 'All Surveyors', icon: 'fa-user-friends'},
    {name: 'Surveyor Call Stats', icon: 'fa-chart-bar'},
    {name: 'Assignments', icon: 'fa-file-alt'},
    {name: 'Call Log', icon: 'fa-history'},
    {name: 'User Directory', icon: 'fa-user-cog'},
    {name: 'Look Up', icon: 'fa-search-plus'}
  ],
  OPS: [
    {name: 'My Projects', icon: 'fa-clipboard-list'},
    {name: 'All Surveyors', icon: 'fa-user-friends'},
    {name: 'Call Quality', icon: 'fa-phone-alt', className: isDev ? '' : 'invisible'},
    {name: 'Surveyor Call Stats', icon: 'fa-chart-bar'},
    {name: 'Comment Cleaning Selection', icon: 'fa-comments'},
    {name: 'Client Report Exports', icon: 'fa-tools'},
    {name: 'Escalations', icon: 'fa-cog'},
    {name: 'Surveyor Earnings', icon: 'fa-dollar-sign'},
    {name: 'Assignments', icon: 'fa-file-alt'},
    {name: 'Call Log', icon: 'fa-history'},
    {name: 'Question Bank Manager', icon: 'fa-question-circle'},
    {name: 'Questionnaires', icon: 'fa-question-circle'},
    {name: 'User Directory', icon: 'fa-user-cog'},
    {name: 'Look Up', icon: 'fa-search-plus'}
  ],
  PM: [
    {name: 'My Projects', icon: 'fa-clipboard-list'},
    {name: 'My Surveyors', icon: 'fa-user-friends'},
    {name: 'Call Quality', icon: 'fa-phone-alt', className: isDev ? '' : 'invisible'},
    {name: 'Surveyor Call Stats', icon: 'fa-chart-bar'},
    {name: 'Comment Cleaning Selection', icon: 'fa-comments'},
    {name: 'Client Report Exports', icon: 'fa-tools'},
    {name: 'Escalations', icon: 'fa-cog'},
    {name: 'Surveyor Earnings', icon: 'fa-dollar-sign'},
    {name: 'Call Log', icon: 'fa-history'},
    {name: 'Questionnaires', icon: 'fa-question-circle'},
    {name: 'User Directory', icon: 'fa-user-cog'},
    {name: 'Look Up', icon: 'fa-search-plus'}
  ],
  QA: [
    {name: 'Call Log', icon: 'fa-history'},
    {name: 'User Directory', icon: 'fa-user-cog'}
  ],
  Surveyor: [
    {name: 'My Projects', icon: 'fa-clipboard-list'},
    {name: 'Surveyor Call Stats', icon: 'fa-chart-bar'},
    {name: 'Surveyor Earnings', icon: 'fa-dollar-sign'},
    {name: 'Call Log', icon: 'fa-history'},
    {name: 'Look Up', icon: 'fa-search-plus'}
  ],
  KellerSurveyor: [
    {name: 'My Projects', icon: 'fa-clipboard-list'},
    {name: 'Call Log', icon: 'fa-history'},
    {name: 'Surveyor Call Stats', icon: 'fa-chart-bar'},
    {name: 'Look Up', icon: 'fa-search-plus'}
  ],
};
